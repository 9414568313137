<script setup>
import useTransportOrders from "@/hooks/useTransportOrders";
import { onMounted, ref } from "vue";

const dialog = ref(true);
const { orderStatuses, fetchOrderStatus } = useTransportOrders();
const updatedData = ref({
    status_id: null,
    notes: null,
    // customer_notes: null, // currently not used
});

const props = defineProps({
    callback: Function,
    orderId: {
        type: Number,
        required: true,
    }
});

onMounted( async() => {
    await fetchOrderStatus();
})
</script>

<template>
    <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card>
            <v-card-title v-if="orderId">{{orderId}} // Change Status</v-card-title>
            <v-divider/>
            
            <v-card-text>
                <v-autocomplete
                    v-model="updatedData.status_id"
                    density="compact"
                    :items="orderStatuses"
                    item-title="name"
                    item-value="id"
                    hide-details
                    variant="outlined"
                    label="New Status"
                />
                <v-textarea
                    v-model="updatedData.notes"
                    class="my-4"
                    density="compact"
                    variant="outlined"
                    hide-details
                    label="Internal Note"
                ></v-textarea>
                <!-- currently not used -->
                <v-textarea
                    density="compact"
                    variant="outlined"
                    hide-details
                    label="Note to Customer"
                ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="callback(false)">
                    Cancel
                </v-btn>
                <v-btn color="success" variant="outlined" @click="callback(true, updatedData)">
                    Change
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

