<script setup>
import useTransportOrders from "@/hooks/useTransportOrders";
import { onMounted } from "vue";

const { fetchActiveLoads, activeLoadsList } = useTransportOrders();

const props = defineProps({
    callback: Function,
    transportOrder: {
        type: Object
    },
});

onMounted( async() => {
    await fetchActiveLoads();
})

</script>

<template>
    <v-dialog max-width="600" persistent>
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" v-if="props?.transportOrder?.load_id" variant="text">#{{
                props?.transportOrder?.load_id }}</v-btn>
            <v-btn v-bind="activatorProps" v-else size="x-small" variant="text">SELECT</v-btn>
        </template>
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span> TR-{{ props?.transportOrder?.id }} &bull; Select Load </span>
                    
                    <v-btn icon="mdi-close" @click="() => {
                        isActive.value = false;
                    }"></v-btn>
                </v-card-title>
                <v-divider/>
                
                <v-container>
                    <v-list>
                        <v-list-item link v-for="load in activeLoadsList" :key="load.id"
                            @click="() => {
                                isActive.value = false;
                                callback(load);
                            }">
                            <div class="p-3">
                                {{ load.id }} &bull; {{ load.created_at }} <br />
                                <strong>Driver</strong>: <small>{{ load.driver_id || 'N/A' }} </small><br />
                                <strong>Trailer</strong>: <small>{{ load.trailer || 'N/A' }} </small><br />
                            </div>
                        </v-list-item>
                    </v-list>
                </v-container>

                <v-divider/>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" variant="text" @click="() => {
                        isActive.value = false;
                    }">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

