<script setup>
import EditTransportOrderPopUP from '@/components/transport/EditTransportOrderPopUP.vue';
import ChangeOrderStatusPopUp from '@/components/transport/ChangeOrderStatusPopUp.vue';
import LoadSelectPopUP from '@/components/transport/LoadSelectPopUP.vue';
import { ref } from 'vue';
import {TRANSPORT_ORDERS_ROUTE} from "@/constants/constants.js";
import useTransportOrders from '@/hooks/useTransportOrders';

const props = defineProps({
    transportOrder: {
        type: Object,
        required: true,
    },
});

const { updateTransportOrder, printDocs } = useTransportOrders()

const changeStatusOpen = ref(false);

const { changeOrderStatus } = useTransportOrders();

const navItems = (id) => {
    return [
        { text: "Map", icon: "mdi-map", to: `/${TRANSPORT_ORDERS_ROUTE}/${id}` },
        {
            text: "Files",
            icon: "mdi-file-outline",
            to: `/${TRANSPORT_ORDERS_ROUTE}/${id}/files`
        },
        {
            text: "History",
            icon: "mdi-history",
            to: `/${TRANSPORT_ORDERS_ROUTE}/${id}/history`
        },
        {
            text: "Billable Activity",
            icon: "mdi-receipt",
            to: `/${TRANSPORT_ORDERS_ROUTE}/${id}/BillableActivity`
        }
    ];
};

const printItems = (id) => {
    return [{ text: "Bill of Lading", icon: "mdi-printer", click: () => { printDocs(`/transport_orders/${id}/bols`) } },
    { text: "Pack Slip", icon: "mdi-printer", click: () => { printDocs(`/transport_orders/${id}/pack_slip`) } }
    ];
};

const editTransportOrder = async (updatedData) => {
    const res = await updateTransportOrder(props.transportOrder.id, updatedData);
}

const updateOrderStatus = async (update, updatedData) => {
    if (update) {
        const response = await changeOrderStatus(props.transportOrder.id, updatedData);
        if (response) {
            changeStatusOpen.value = false;
            props.transportOrder.order_status.name = response.order_status.name;
        }
    } else {
        changeStatusOpen.value = false;
    }
}

const selectLoad = async (selectedLoad) => {
    const response = await updateTransportOrder(props.transportOrder.id, { load_id: selectedLoad.id });
    if (response) {
        props.transportOrder.load_id = response.load_id;
    }
}

</script>

<template>
    <div class="ml-4 w-100">
        <a class="text-h6" :href="'/' + TRANSPORT_ORDERS_ROUTE">Transportation Orders</a> // {{ transportOrder?.id }}
        <v-divider></v-divider>
    </div>

    <v-card flat class="d-flex ml-4 h-screen border-b-sm">
        <div class="w-25 h-screen overflow-y-auto border-e-sm border-s-sm">
            <div v-if="transportOrder" class="mb-2 mt-4">
                <div class="w-80 ma-4">
                    <v-btn :color="transportOrder?.status?.color" block variant="outlined"
                        @click="changeStatusOpen = true">
                        {{ transportOrder.order_status?.transport_name || transportOrder.order_status?.name }}
                    </v-btn>
                </div>
                <ChangeOrderStatusPopUp v-if="changeStatusOpen" :callback="updateOrderStatus" :orderId="transportOrder?.id"/>
                <v-divider></v-divider>
            </div>
            <div class="ma-4">
                <div class="mb-4 text-subtitle-1">
                    <p><strong>Pick</strong></p>
                    <p>{{ transportOrder?.pick_name }}</p>
                    <p v-if="transportOrder?.pick_address">{{ transportOrder.pick_address }}</p>
                    <p v-if="transportOrder?.pick_csz">{{ transportOrder.pick_csz }}</p>
                    <p>Dock Hours: {{ transportOrder?.pick_dock_hours }}</p>
                    <p>Appt: {{ transportOrder?.pick_appt_time }}</p>
                </div>
                <div class="mb-4 text-subtitle-1">
                    <p><strong>Drop</strong></p>
                    <p>{{ transportOrder?.drop_name }}</p>
                    <p>{{ transportOrder?.drop_address }}</p>
                    <p>{{ transportOrder?.drop_csz }}</p>
                    <p class="text-muted">Dock Hours:{{ transportOrder?.drop_dock_hours }}</p>
                    <p class="text-muted">Appt:{{ transportOrder?.drop_appt_time }}</p>
                </div>
                <div class="mb-4 text-subtitle-1">
                    <p><strong>PO:</strong></p>
                    <p>{{ transportOrder?.po_number }}</p>
                </div>
                <div class="mb-4 text-subtitle-1">
                    <p><strong>Ref:</strong></p>
                    <p>{{ transportOrder?.customer_ref || 'N/A' }}</p>
                </div>
                <div class="mb-4 text-subtitle-1">
                    <p><strong>Cargo</strong></p>
                    <p>{{ transportOrder?.cargo_qty }} {{ transportOrder?.cargo_type }}</p>
                    Total Wt: {{ transportOrder?.cargo_weight }} lbs.
                </div>
                <p class="text-subtitle-1">
                    <strong>Load</strong>&nbsp;&nbsp;
                    <LoadSelectPopUP :transportOrder="transportOrder" :callback="selectLoad" />
                </p>

                <EditTransportOrderPopUP :transportOrder="props.transportOrder" :callback="editTransportOrder" />
            </div>

            <v-divider></v-divider>

            <v-card flat class="mx-auto pa-2 w-100">
                <div class="text-center" v-if="!transportOrder">
                    <v-progress-circular :width="8" :size="50" color="primary" class="mb-16"
                        indeterminate></v-progress-circular>
                </div>
                <v-list v-if="props.transportOrder">
                    <v-list-item exact v-if="transportOrder" v-for="(item, i) in navItems(transportOrder.id)" :key="i" :value="item" :to="item.to"
                        color="primary">
                        <template v-slot:prepend>
                            <v-icon :icon="item.icon"></v-icon>
                        </template>

                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-list v-if="props.transportOrder">
                    <v-list-subheader>PRINT</v-list-subheader>

                    <v-list-item v-if="transportOrder" v-for="(item, i) in printItems(transportOrder.id)" :key="i" :value="item"
                        color="primary" rounded="xl" @click="item.click ? item.click() : null">
                        <template v-slot:prepend>
                            <v-icon :icon="item.icon"></v-icon>
                        </template>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </div>
        <div class="w-75 h-screen overflow-y-auto">
            <slot />
        </div>
    </v-card>
</template>

<style>
.detail-left-section {
    background-color: white;
}

@media (max-width: 768px) {
    .v-card .mx-auto {
        max-width: 75px;
    }
}
</style>