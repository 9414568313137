<script setup>
import useTransportOrders from "@/hooks/useTransportOrders";
import { dayMonthYearFormat } from "@/utils/dateUtils";
import { onMounted, ref, watch } from "vue";

const pickUpMenu = ref(false);
const dropMenu = ref(false);
const pickUpDate = ref(new Date(Date.now()));
const dropOffDate = ref(new Date(Date.now()));
const updatedData = ref(null);
const statesArray= ref([
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
    ]);

const { fetchAdditionalServices, fetchCargoTypes, cargoTypes, additionalServices } = useTransportOrders();

const props = defineProps({
    callback: Function,
    transportOrder: {
        type: Object,
        required: true,
    },
});

onMounted(() => {
    updatedData.value = props.transportOrder;
    fetchCargoTypes();
    fetchAdditionalServices();
    
    // Set default values if they are set as null in the backend
    checkForNull();
    // Map the date if available
    if (updatedData.value.pick_appt) {
        pickUpDate.value = new Date(updatedData.value.pick_appt);
    } 
    if (updatedData.value.drop_appt) {
        dropOffDate.value = new Date(updatedData.value.drop_appt);
    }
})

const checkForNull = () => {   
    if (!props.transportOrder.pick_appt_required) {
        updatedData.value.pick_appt_required = false;
    }
    if (!props.transportOrder.pick_appt_confirmed) {
        updatedData.value.pick_appt_confirmed = false;
    }
    if (!props.transportOrder.drop_appt_required) {
        updatedData.value.drop_appt_required = false;
    }
    if (!props.transportOrder.drop_appt_confirmed) {
        updatedData.value.drop_appt_confirmed = false;
    }
}

watch(pickUpDate, (newValue) => {
    if (newValue) {
        updatedData.value.pick_appt = dayMonthYearFormat(newValue);
    }
});

watch(dropOffDate, (newValue) => {
    if (newValue) {
        updatedData.value.drop_appt = dayMonthYearFormat(newValue);
    }
});
</script>

<template>
    <v-dialog width="auto" persistent>
        <template v-slot:activator="{ props: activatorProps }">
            <p class="mt-3">
                <v-btn block variant="text" color="blue" v-bind="activatorProps">
                    Edit
                </v-btn>
            </p>
        </template>
        <template v-slot:default="{ isActive }">
            <v-card>
                <v-card-title>Pickup</v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="d-flex justify-space-between ga-4">
                                <v-text-field
                                    v-model="updatedData.pick_appt"
                                    class="w-50"
                                    density="compact"
                                    hide-details
                                    id="pickup-menu-activator"
                                    label="Date"
                                    placeholder="dd/mm/yyyy"
                                    variant="outlined"
                                    readonly
                                ></v-text-field>
                                    <v-menu
                                        v-model="pickUpMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        activator="#pickup-menu-activator"
                                    >
                                        <v-date-picker
                                            v-model="pickUpDate"
                                            @update:modelValue="() => { pickUpMenu = false }"
                                            show-adjacent-months
                                        ></v-date-picker>
                                    </v-menu>
                                <v-text-field
                                    v-model="updatedData.pick_dock_hours"
                                    class="w-50"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    label="Dock Hours"
                                    placeholder="Ex: 8am - 5pm"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="updatedData.pick_name"
                                density="compact"
                                variant="outlined"
                                hide-details
                            >
                                <template v-slot:label>
                                    Name 
                                    <!-- <span class="text-red">*</span> -->
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="d-sm-flex justify-space-between ga-4">
                                <div class="w-100 w-sm-50">
                                    <v-checkbox
                                        v-model="updatedData.pick_appt_required"
                                        density="compact"
                                        variant="outlined"
                                        label="Appointment Required"
                                        hide-details
                                        color="primary"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="transportOrder.pick_appt_confirmed"
                                        density="compact"
                                        variant="outlined"
                                        label="Appointment Confirm"
                                        hide-details
                                        color="primary"
                                    ></v-checkbox>
                                </div>
                                <v-textarea
                                    v-if="updatedData.pick_appt_required"
                                    v-model="updatedData.pick_appt_contact"
                                    rows="3"
                                    class="w-100 w-sm-50"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    label="Appointment Contact Info"
                                ></v-textarea>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="updatedData.pick_address"
                                density="compact"
                                variant="outlined"
                                hide-details
                            >
                                <template v-slot:label>
                                    Address 
                                    <!-- <span class="text-red">*</span> -->
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                multiple
                                chips
                                density="compact"
                                :items="additionalServices"
                                hide-details
                                variant="outlined"
                                label="Additional Services"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="d-sm-flex justify-space-between ga-4">
                                <v-text-field
                                    v-model="updatedData.pick_city"
                                    class="w-100 w-sm-25"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        City 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-text-field>

                                <v-autocomplete
                                    v-model="updatedData.pick_state"
                                    class="my-4 my-sm-0"
                                    density="compact"
                                    :items="statesArray"
                                    hide-details
                                    variant="outlined"
                                >
                                    <template v-slot:label>
                                        State 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-autocomplete>

                                <v-text-field
                                    v-model="updatedData.pick_zip"
                                    class="w-100 w-sm-25"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        Zip 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-title>Drop Off</v-card-title>
                <v-divider></v-divider>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="d-flex justify-space-between ga-4">
                                <v-text-field
                                    v-model="updatedData.drop_appt"
                                    class="w-50"
                                    density="compact"
                                    hide-details
                                    id="drop-menu-activator"
                                    label="Date"
                                    placeholder="dd/mm/yyyy"
                                    variant="outlined"
                                    readonly
                                ></v-text-field>
                                    <v-menu
                                        v-model="dropMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        activator="#drop-menu-activator"
                                    >
                                        <v-date-picker
                                            v-model="dropOffDate"
                                            @update:modelValue="() => { dropMenu = false }"
                                            show-adjacent-months
                                        ></v-date-picker>
                                    </v-menu>
                                <v-text-field
                                    v-model="updatedData.drop_dock_hours"
                                    class="w-50"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    label="Dock Hours"
                                    placeholder="Ex: 8am - 5pm"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="updatedData.drop_name"
                                density="compact"
                                variant="outlined"
                                hide-details
                            >
                                <template v-slot:label>
                                    Name 
                                    <!-- <span class="text-red">*</span> -->
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="d-sm-flex justify-space-between ga-4">
                                <div class="w-100 w-sm-50">
                                    <v-checkbox
                                        v-model="updatedData.drop_appt_required"
                                        density="compact"
                                        variant="outlined"
                                        label="Appointment Required"
                                        hide-details
                                        color="primary"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="updatedData.drop_appt_confirmed"
                                        density="compact"
                                        variant="outlined"
                                        label="Appointment Confirm"
                                        hide-details
                                        color="primary"
                                    ></v-checkbox>
                                </div>
                                <v-textarea
                                    v-if="updatedData.drop_appt_required"
                                    v-model="updatedData.drop_appt_contact"
                                    rows="3"
                                    class="w-100 w-sm-50"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    label="Appointment Contact Info"
                                ></v-textarea>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="updatedData.drop_address"
                                density="compact"
                                variant="outlined"
                                hide-details
                            >
                                <template v-slot:label>
                                    Address 
                                    <!-- <span class="text-red">*</span> -->
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                multiple
                                chips
                                density="compact"
                                :items="additionalServices"
                                hide-details
                                variant="outlined"
                                label="Additional Services"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="d-sm-flex justify-space-between ga-4">
                                <v-text-field
                                    v-model="updatedData.drop_city"
                                    class="w-100 w-sm-25"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        City 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-text-field>

                                <v-autocomplete
                                    v-model="updatedData.drop_state"
                                    class="my-4 my-sm-0"
                                    density="compact"
                                    :items="statesArray"
                                    hide-details
                                    variant="outlined"
                                >
                                    <template v-slot:label>
                                        State 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-autocomplete>

                                <v-text-field
                                    v-model="updatedData.drop_zip"
                                    class="w-100 w-sm-25"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        Zip 
                                        <!-- <span class="text-red">*</span> -->
                                    </template>
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text>

                    <div class="d-sm-flex justify-space-between ga-4 mb-4">
                        <v-text-field
                            v-model="updatedData.po_number"
                            density="compact"
                            variant="outlined"
                            hide-details
                            label="PO Number"
                        >
                        </v-text-field>
                        <v-text-field
                            v-model="updatedData.customer_ref"
                            class="my-4 my-sm-0"
                            density="compact"
                            variant="outlined"
                            hide-details
                            label="Ref Number"
                        >
                        </v-text-field>
                        <v-autocomplete
                            v-model="updatedData.cargo_type"
                            density="compact"
                            :items="cargoTypes"
                            hide-details
                            variant="outlined"
                            label="Cargo Type"
                        >
                        </v-autocomplete>
                        <v-text-field
                            v-model="updatedData.cargo_qty"
                            class="my-4 my-sm-0"
                            density="compact"
                            variant="outlined"
                            hide-details
                            label="Cargo Qty"
                        >
                        </v-text-field>

                        <v-text-field
                            v-model="updatedData.cargo_weight"
                            density="compact"
                            variant="outlined"
                            hide-details
                            label="Total Weight"
                        >
                        </v-text-field>
                    </div>

                    <v-textarea
                        v-model="updatedData.notes"
                        density="compact"
                        variant="outlined"
                        hide-details
                        label="Notes"
                    ></v-textarea>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" variant="text" @click="() => isActive.value = false">
                        Cancel
                    </v-btn>
                    <v-btn color="success" variant="outlined" @click="() => {
                        isActive.value = false;
                        callback(updatedData);
                    }">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

